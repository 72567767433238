<template>
  <picture class="hb-image">
    <template v-if="!showLargest">
      <template v-if="!hasError">
        <source
          v-for="(item, index) in srcSets"
          :key="index"
          :media="getMedia(index, item.width)"
          :srcset="item.src"
        />
      </template>
      <img
        v-if="srcSets[0] && !hasError"
        :src="srcSets?.[0]?.src || placeholderSrc"
        alt=""
        :loading="loadingType"
        @error="hasError = true"
      />
      <img v-if="hasError" :src="placeholderSrc" alt="" />
    </template>
    <template v-else>
      <img
        v-if="largestImage && !hasError"
        :src="largestImage?.src || placeholderSrc"
        alt=""
        :loading="loadingType"
        @error="hasError = true"
      />
      <img v-if="hasError" :src="placeholderSrc" alt="" />
    </template>
  </picture>
</template>

<script lang="ts">
import type { SrcSet } from '@homebourse/api-client'
import { computed, ref, watch } from 'vue'
import { maxBy, sortBy } from 'lodash-es'
import { useWindowSize } from '@vueuse/core'
import { preloadImages } from 'wue'
import { HOME_PLACEHOLDER_SRC } from '~/modules/home/constants'

interface PictureSrc {
  width: number
  src: string
}

export default {
  name: 'HbImage',
  props: {
    image: { type: Object as () => SrcSet, required: true },
    loadingType: {
      type: String as () => 'lazy' | 'eager',
      default: () => 'lazy',
    },
    showLargest: { type: Boolean, default: () => false },
    allowImagePreload: { type: Boolean, default: () => false },
  },
  setup(props) {
    const placeholderSrc = ref(HOME_PLACEHOLDER_SRC)
    const hasError = ref(false)
    const { width: windowWidth } = useWindowSize()
    const srcSets = computed<PictureSrc[]>(() => {
      let sets: PictureSrc[] = []
      ;(props.image?.data || []).forEach((srcSet) => {
        if (['webp'].includes(srcSet.type)) {
          sets.push({ width: srcSet.width, src: srcSet.src })
        }
      })

      sets = sortBy(sets, 'width')

      return sets
    })
    const largestImage = computed(() =>
      maxBy(srcSets.value, (item) => item.width)
    )
    const visibleImage = computed(() =>
      srcSets.value.find((item, index) => {
        const nextItem = srcSets.value[index + 1]

        if (nextItem) {
          return (
            item.width <= windowWidth.value &&
            nextItem.width > windowWidth.value
          )
        }

        return item.width <= windowWidth.value
      })
    )
    const getMedia = (index: number, width: number) => {
      const nextSrc = srcSets.value[index + 1]

      if (index === 0 && nextSrc) {
        return `(max-width: ${nextSrc.width - 1}px)`
      }

      return (
        `(min-width: ${width}px)` +
        (nextSrc ? ` and (max-width: ${nextSrc.width - 1}px)` : '')
      )
    }

    watch(
      () => props.allowImagePreload,
      () => {
        if (visibleImage.value && props.allowImagePreload) {
          preloadImages([visibleImage.value?.src])
        }
      },
      { immediate: true }
    )

    return {
      placeholderSrc,
      srcSets,
      hasError,
      largestImage,
      getMedia,
    }
  },
}
</script>

<style lang="scss">
.hb-image {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}
</style>
